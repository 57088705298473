import Tracker, { ITrackerResponse } from './Tracker'
import Pusher, { ERROR, NotificationPermission } from './Pusher'
import ConsentManager from './ConsentManager'
import Attention from './Attention'
import { isPushApiSupported } from './util'
import FacebookAds from './FacebookAds'
import Service from './Service'
import { PromisePamEvent } from './PamEvent'
import Queue from './datastructure/Queue'
import PamExtension from './Extension/PamExtension'

// Only for new-CMS
import NotificationPopup from './ConsentManager/NotificationPopup'
export { NotificationPopup }

export interface IConfig {
  // deprecated
  trackerApi?: string

  autoTracking: boolean
  webPushPublicKey: string
  baseApi: string
  pusherAlias: string
  enableFacebookTracking?: boolean
  disableWebAttention?: boolean
  prefixPathWorkerScript?: string
  trackingConsentMessageId: string
  contactingConsentMessageIds?: string[]
  publicDBAlias: string
  loginDBAlias: string
  loginKey: string

  disableInstantPopupUI?: boolean
}

export interface IContext {
  config: IConfig
}

export default class Pam4Tracker {
  private readonly ctx!: IContext
  public consentManager!: ConsentManager
  public tracker!: Tracker
  public attention!: Attention
  public pusher: Pusher | null = null
  public facebookAds!: FacebookAds
  public service!: Service
  private eventQueue!: Queue<PromisePamEvent>

  constructor(config: IConfig) {
    if (!config.baseApi && config.trackerApi) {
      config.baseApi = config.trackerApi.replace('/trackers/events', '')
    }

    if (typeof config.enableFacebookTracking === 'undefined') {
      config.enableFacebookTracking = false
    }
    if (typeof config.disableWebAttention === 'undefined') {
      config.disableWebAttention = false
    }
    if (typeof config.disableInstantPopupUI === 'undefined') {
      config.disableInstantPopupUI = false
    }

    if (!config.publicDBAlias) {
      config.publicDBAlias = 'default'
      console.warn('publicDBAlias is not defined, use default')
    }
    if (!config.loginDBAlias) {
      config.loginDBAlias = 'default'
      console.warn('logigDBAlias is not defined, use default')
    }

    config.prefixPathWorkerScript = config.prefixPathWorkerScript || ''

    this.ctx = {
      config,
    }

    this.eventQueue = new Queue()

    this.service = new Service(this.ctx, this.eventQueue)

    this.consentManager = new ConsentManager(this.ctx, this.service)

    if (!config.disableWebAttention) {
      this.attention = new Attention(this.ctx)
    }

    this.consentManager.start()

    this.tracker = new Tracker(this.ctx, this.service, this.attention, this.consentManager, this.eventQueue)

    if (config.enableFacebookTracking) {
      window.addEventListener(
        'send_tracking',
        () => {
          this.facebookAds = new FacebookAds(this.ctx)
          this.facebookAds.init()
        },
        { once: true }
      )
    }

    if (isPushApiSupported() && config.webPushPublicKey) {
      this.initPusher()
    }

    PamExtension.init()

    PamExtension.log('Pam Start', this.getContactID(), {
      ...config,
    })

    setTimeout(() => {
      window.dispatchEvent(new Event('pam-ready'))
    }, 100)
  }

  public async track(
    eventName: string,
    formFields: Record<string, unknown>,
    tags: string[] = []
  ): Promise<ITrackerResponse | { error: string }> {
    try {
      return await this.tracker.send(eventName, tags.join(','), formFields)
    } catch (e) {
      return { error: String(e) }
    }
  }

  public async send(
    eventName: string,
    tags: string,
    formFields: Record<string, unknown>
  ): Promise<ITrackerResponse | { error: string }> {
    try {
      return await this.tracker.send(eventName, tags, formFields)
    } catch (e) {
      return { error: String(e) }
    }
  }

  public getContactID(): string {
    return this.tracker.getContactID()
  }

  private initPusher(): void {
    this.pusher = new Pusher()
    this.pusher
      .init(this.ctx)
      .then((cfg) => {
        if (cfg.autoSubscribe) {
          this.pusher!.subscribe()
        } else if (cfg.enableWebPushPopup && this.pusher!.permission === NotificationPermission.DEFAULT) {
          this.pusher!.render(cfg)
        }
      })
      .catch((err) => {
        if (err !== ERROR.USER_IS_SUBSCRIBED) {
          window.console.error(err)
        }
      })
  }
}
