import {
  DBType,
  getContactId,
  getCurrentDBType,
  getFormatData,
  postRequester,
  sleep,
  windowOrigin,
  writeCookie,
  parseBool,
} from '../util'
import Attention from '../Attention'
import ConsentManager from '../ConsentManager'
import { IContext } from '../pam4-tracker'
import { PromisePamEvent } from '../PamEvent'
import Queue from '../datastructure/Queue'
import Service from '../Service'
import GoogleAnalytics from './GoogleAnalytics'
import FacebookPixel from './FacebookPixel'
import Log from '../Extension/PamExtension'

export interface ITrackerResponse {
  contact_id: string
  consent_id?: string
  _database?: string
}

export interface IQueryParam {
  [key: string]: string
}

export default class Tracker {
  private contactID = ''
  private readonly iframeSrcURL: string
  private readonly iframeOrigin: string
  private sentAutoTracking = false
  private tags = ''
  private formFields: Record<string, unknown> = {}
  public googleAnalytics: GoogleAnalytics | undefined
  public facebookPixel: FacebookPixel | undefined

  constructor(
    private ctx: IContext,
    private service: Service,
    private attention: Attention,
    private consentManager: ConsentManager,
    private eventQueue: Queue<PromisePamEvent>
  ) {
    this.iframeOrigin = this.ctx.config.baseApi + '/script'
    this.iframeSrcURL =
      this.iframeOrigin +
      '/xdomain_cookie.html#' +
      '{"window_origin":"' +
      windowOrigin() +
      '","iframe_origin":"' +
      this.iframeOrigin +
      '"}'

    this._initIFrame()
    window.addEventListener('message', this._onMessage)
    this.startEventQueueManager()
    this.setupThirdPartyTracker()
  }

  public getContactID(): string {
    return this.contactID
  }

  // Manual tracking others event type
  public async send(eventName: string, tags: string, formFields: Record<string, unknown>): Promise<ITrackerResponse> {
    while (this.consentManager.isFetching) {
      await sleep(200)
    }

    if (this.isTrackingEvent(eventName) && !this.isAllowConsentFormField(formFields)) {
      if (!this.consentManager.isTrackingAllowed) {
        Log.log('Tracking is not Allow', '', {
          ...getFormatData(eventName, formFields, tags),
        })

        throw new Error('tracking is not allowed')
      }
      formFields._consent_message_id = this.ctx.config.trackingConsentMessageId
      formFields._version = this.consentManager.consentMessage.setting.version
    }

    if (!formFields._database) {
      formFields._database =
        getCurrentDBType() === DBType.LOGIN ? this.ctx.config.loginDBAlias : this.ctx.config.publicDBAlias
    }

    const queryObject = this.getObjectFromQuery()
    const contactId = queryObject?.contact_id || getContactId()

    if (this.consentManager.hasLoggedIn && this.consentManager.loginIdKey && this.consentManager.loginId) {
      formFields[this.consentManager.loginIdKey] = this.consentManager.loginId
    } else if (contactId) {
      formFields._contact_id = contactId
    }

    const formData = {
      ...getFormatData(eventName, formFields, tags),
    }

    try {
      const data = await postRequester<ITrackerResponse>(`${this.ctx.config.baseApi}/trackers/events`, formData)
      this.contactID = data.contact_id

      const trackingEvent: CustomEventInit = {
        detail: {
          contact_id: data.contact_id,
          event: formData,
        },
      }

      writeCookie('contact_id', data.contact_id, 365 * 5)
      window.localStorage.setItem('contact_id', data.contact_id)
      this._setXDomainCookie(data)

      if (data.contact_id && (this.mustRefreshConsentEvent(eventName) || this.isAllowConsentFormField(formFields))) {
        await this.consentManager.refreshTrackingConsentStatus()
      }

      Log.log('Send', data.contact_id, { ...formData })

      window.dispatchEvent(new CustomEvent('send_tracking', trackingEvent))
      return data
    } catch (e) {
      return e as any
    }
  }

  private _initIFrame(): void {
    if (!document.getElementById('xdomain_cookie_iframe')) {
      const iframe = window.document.createElement('iframe')
      iframe.style.display = 'none'
      iframe.src = this.iframeSrcURL
      iframe.id = 'xdomain_cookie_iframe'
      window.document.body.appendChild(iframe)
    }
  }
  private _onMessage = async (e: MessageEvent) => {
    while (this.consentManager.isTrackingConsentRefreshing || this.consentManager.isFetching) {
      await sleep(200)
    }
    if (!this.consentManager.isTrackingAllowed) {
      return
    }
    this._setLocalCookie(this._readingIframeCookie(e))
    if (this.ctx.config.autoTracking && !this.sentAutoTracking) {
      this.service
        .sendEvent('page_view', this.tags, this.formFields)
        .then(() => {
          if (this.attention) {
            this.attention.startFetchInterval()
          }
        })
        .catch(console.error)

      if (this.googleAnalytics) {
        this.googleAnalytics.track()
      }

      this.sentAutoTracking = true
    }
  }

  private _readingIframeCookie(e: MessageEvent): void {
    if (e.origin !== this.iframeOrigin) return
    if (typeof e.data !== 'string') return

    let data = null
    try {
      data = JSON.parse(e.data)
    } catch (e) {
      data = null
    }

    if (typeof data !== 'object' || data instanceof Array) return
    if (!('msg_type' in data) || data.msg_type !== 'xdomain_cookie_read') return

    return data.cookies
  }

  private _setLocalCookie(cookies: any): void {
    for (const name in cookies) {
      if (typeof cookies[name] !== 'function') {
        writeCookie(name, cookies[name])
      }
    }
  }

  private _setXDomainCookie(cookies: any): void {
    const msg = {
      cookies: cookies,
      msg_type: 'xdomain_cookie_write',
    }

    // post message to iframe window w/ data
    const el = window.document.getElementById('xdomain_cookie_iframe') as HTMLIFrameElement
    if (el && el.contentWindow) {
      el.contentWindow.postMessage(JSON.stringify(msg), this.iframeOrigin)
    }
  }

  public getObjectFromQuery(): IQueryParam {
    const query = window.location.search
    if (query === '') {
      return {}
    }
    const objectQuery = query
      .slice(1)
      .split('&')
      .map((kv) => ({ [kv.split('=')[0]]: kv.split('=')[1] }))
    let tmp: IQueryParam = {}
    objectQuery.forEach((object: Record<string, string>) => {
      Object.keys(object).forEach((key: string) => {
        tmp = {
          ...tmp,
          [key]: object[key],
        }
      })
    })

    return tmp
  }

  private isTrackingEvent(eventName: string): boolean {
    return !['allow_consent', 'register', 'register_success', 'login', 'logout', 'save_push'].includes(eventName)
  }

  private mustRefreshConsentEvent(eventName: string): boolean {
    return ['allow_consent'].includes(eventName)
  }

  private isAllowConsentFormField(formFields: Record<string, unknown>): boolean {
    for (const key in formFields) {
      if (key === '_allow_consent') {
        const keyType = typeof formFields[key]
        if (keyType === 'string') {
          return parseBool(`${formFields[key]}`)
        } else if (keyType === 'boolean') {
          return formFields[key] as boolean
        }
        break
      }
    }
    return false
  }

  private startEventQueueManager(): void {
    this.consumeEventQueue()
  }

  private async consumeEventQueue(): Promise<void> {
    while (!this.eventQueue.isEmpty) {
      const event = this.eventQueue.dequeue()!
      try {
        const res = await this.send(event.name, event.tagsString, event.formFields)
        event.resolve(res)
      } catch (e) {
        event.reject(e)
      }
    }
    // if the queue is empty then sleep 200 ms before recursively consuming event queue
    await sleep(200)
    this.consumeEventQueue()
  }

  private async setupThirdPartyTracker(): Promise<void> {
    while (this.consentManager.isFetching) {
      await sleep(200)
    }
    this.googleAnalytics = new GoogleAnalytics(this.consentManager)
    this.googleAnalytics.installScript()

    this.facebookPixel = new FacebookPixel(this.consentManager)
    this.facebookPixel.installScript()
  }
}
