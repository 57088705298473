import { IConsentMessage, IPermissionBody, ITrackerResponse } from '../Service/types'

export default class PermissionManagement {
  constructor(
    private consentDetail: IConsentMessage,
    private submitConsentPermission?: (consentAcceptation: IPermissionBody) => Promise<ITrackerResponse>
  ) {
    this.init() // Init enabled permission data
  }

  public permission: Record<string, Record<string, any>> = {}

  trackingConsentDetailType: Record<string, Record<string, any>> = {
    termsAndConditions: {
      nameStr: 'Terms & Conditions',
      key: 'terms_and_conditions',
      allow: false,
    },
    privacyOverview: {
      nameStr: 'Privacy Overview',
      key: 'privacy_overview',
      allow: false,
    },
    necessaryCookies: {
      nameStr: 'Necessary Cookies',
      key: 'necessary_cookies',
      allow: false,
    },
    preferencesCookies: {
      nameStr: 'Preferences Cookies',
      key: 'preferences_cookies',
      allow: false,
    },
    analyticsCookies: {
      nameStr: 'Analytics Cookies',
      key: 'analytics_cookies',
      allow: false,
    },
    marketingCookies: {
      nameStr: 'Marketing Cookies',
      key: 'marketing_cookies',
      allow: false,
    },
    socialMediaCookies: {
      nameStr: 'Social Media Cookies',
      key: 'social_media_cookies',
      allow: false,
    },
  }

  contactingTypeDetailOptions: Record<string, Record<string, any>> = {
    termsAndConditions: {
      nameStr: 'Terms & Conditions',
      key: 'terms_and_conditions',
      allow: false,
    },
    privacyOverview: {
      nameStr: 'Privacy Overview',
      key: 'privacy_overview',
      allow: false,
    },
    email: {
      nameStr: 'Email',
      key: 'email',
      allow: false,
    },
    sms: {
      nameStr: 'SMS',
      key: 'sms',
      allow: false,
    },
    line: {
      nameStr: 'LINE',
      key: 'line',
      allow: false,
    },
    facebookMessenger: {
      nameStr: 'Facebook Messenger',
      key: 'facebook_messenger',
      allow: false,
    },
    pushNotification: {
      nameStr: 'Push Notification',
      key: 'push_notification',
      allow: false,
    },
  }

  consentAcceptation: IPermissionBody = {
    consent_message_id: this.consentDetail.consent_message_id,
    version: this.consentDetail.setting.version!,
    permission: {},
  }

  private init = (): void => {
    const tempPerm =
      this.consentDetail.consent_message_type === 'tracking_type'
        ? this.trackingConsentDetailType
        : this.contactingTypeDetailOptions

    for (const item in tempPerm) {
      if (this.consentDetail.setting[tempPerm[item]['key']].is_enabled) {
        this.permission[item] = { ...tempPerm[item] }
      }
    }
  }

  public setPermission = (name: string, allow: boolean): void => {
    for (const item in this.permission) {
      const permObj = this.permission[item]
      if (item == name && this.consentDetail.setting[permObj['key']].is_enabled) {
        permObj['allow'] = allow
      }
    }
  }

  public allowAll = (): void => {
    for (const item in this.permission) {
      const permObj = this.permission[item]
      if (this.consentDetail.setting[permObj['key']].is_enabled) {
        permObj['allow'] = true
      }
    }
  }

  public disallowAll = (): void => {
    for (const item in this.permission) {
      const permObj = this.permission[item]
      if (this.consentDetail.setting[permObj['key']].is_enabled) {
        permObj['allow'] = false
        // this.assign(this.permission, item, false)
      }
    }
  }

  public submitConsent = async (): Promise<ITrackerResponse | undefined> => {
    let res: ITrackerResponse

    // Assign properly permission format to submit body
    const submittedPerm: Record<string, boolean> = {}
    for (const item in this.permission) {
      const permObj = this.permission[item]
      submittedPerm[permObj['key']] = permObj['allow']
    }
    this.consentAcceptation.permission = submittedPerm

    try {
      if (this.submitConsentPermission) {
        res = await this.submitConsentPermission(this.consentAcceptation)
        return res
      }
    } catch (e) {
      throw e
    }
  }
}
