import { kill } from 'process'

interface LogItem {
  name: string
  contactId: string
  data: unknown
}

export default class PamExtension {
  private static foundExtension = false
  private static logHistory: LogItem[] = []

  static init(): void {
    window.addEventListener('message', (event) => {
      if (!PamExtension.foundExtension && event.data.method === 'pam-extension-hello') {
        PamExtension.foundExtension = true
        window.postMessage({ method: 'pam-client-hello' }, window.location.origin)
        PamExtension.logHistory.forEach((l) => {
          PamExtension.sendLog(l.name, l.contactId, l.data)
        })
      }

      if (event.data.method === 'pam-extension-full-history') {
        window.postMessage(
          { method: 'pam-client-full-history', history: PamExtension.logHistory },
          window.location.origin
        )
      }
    })
  }

  private static sendLog(name: string, contactId: string, data: unknown) {
    window.postMessage(
      { method: 'pam-client-log', contactId: contactId, name: name, data: data },
      window.location.origin
    )
  }

  static log(name: string, contactId: string, data: unknown): void {
    PamExtension.logHistory.push({
      name: name,
      contactId: contactId,
      data: data,
    })
    if (PamExtension.foundExtension) {
      PamExtension.sendLog(name, contactId, data)
    }
  }
}
