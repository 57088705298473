import Queue from '../datastructure/Queue'
import { IContext } from '../pam4-tracker'
import { PromisePamEvent } from '../PamEvent'
import { getRequester, writeCookie } from '../util'
import { ITrackerResponse, ICustomerConsentDetail, IConsentMessage } from './types'

export default class Service {
  constructor(private ctx: IContext, private eventQueue: Queue<PromisePamEvent>) {}

  public async sendEvent(eventName: string, tags = '', formFields = {}): Promise<ITrackerResponse> {
    let globalResolve!: (res: ITrackerResponse) => void
    let globalReject!: (reason: any) => void
    const promise = new Promise<ITrackerResponse>((resolve, reject) => {
      globalResolve = resolve
      globalReject = reject
    })

    const event = new PromisePamEvent(eventName, formFields, globalResolve, globalReject, tags.split(','))

    this.eventQueue.enqueue(event)

    try {
      const data = await promise
      writeCookie('contact_id', data.contact_id, 365 * 5)
      window.localStorage.setItem('contact_id', data.contact_id)
      return data
    } catch (e) {
      return e as any
    }
  }

  public fetchConsentMessage(consentMessageId = this.ctx.config.trackingConsentMessageId): Promise<IConsentMessage> {
    const url = `${this.ctx.config.baseApi}/consent-message/${consentMessageId}`
    return getRequester(url)
  }

  public getCustomerConsentDetail(contactId: string, consentMsgId: string): Promise<ICustomerConsentDetail> {
    return getRequester(`${this.ctx.config.baseApi}/contacts/${contactId}/consents/${consentMsgId}`)
  }
}
