export const urlB64ToUint8Array = (base64String: string): Uint8Array => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const windowOrigin = (): string => {
  return (
    window.location.protocol +
    '//' +
    window.location.hostname +
    (window.location.port ? ':' + window.location.port : '')
  )
}

export const postRequester = <T>(url: string, data: any): Promise<T> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', url)
    xhr.addEventListener('load', () => {
      const { response, status } = xhr
      if (status >= 200 && status < 400) {
        resolve(JSON.parse(response))
      } else {
        reject(JSON.parse(response))
      }
    })

    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
    xhr.withCredentials = true
    try {
      xhr.send(JSON.stringify(data))
    } catch (err) {
      reject(err)
    }
  })
}

export const getRequester = <T>(url: string, data: any = {}): Promise<T> => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url + objectToQueryString(data))
    xhr.addEventListener('load', () => {
      const { response, status } = xhr
      if (status >= 200 && status < 400) {
        resolve(JSON.parse(response))
      } else {
        reject(JSON.parse(response))
      }
    })

    xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8')
    xhr.withCredentials = true
    try {
      xhr.send()
    } catch (err) {
      reject(err)
    }
  })
}

export const readCookie = (name: string): string | null => {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export const writeCookie = (key: string, value: string, days = 1825) => {
  const date = new Date()

  // Get unix milliseconds at current time plus number of days
  date.setTime(+date + days * 86400000) // 24 * 60 * 60 * 1000
  window.document.cookie = `${key}=${value};expires=${date.toUTCString()};SameSite=None;Secure;path=/`
}

export const toggleClass = (element: HTMLElement, className: string) => {
  if (!element || !className) {
    return
  }

  let classString = element.className
  const nameIndex = classString.indexOf(className)
  if (nameIndex === -1) {
    classString += ' ' + className
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length)
  }
  element.className = classString
}

export const appendStyle = (shadow: ShadowRoot, style: string): void => {
  const elm = window.document.createElement('style')
  elm.innerHTML = style
  shadow.appendChild(elm)
}

export const getPageURL = () => window.document.location && window.document.location.href

export interface IFormatData {
  event: string
  page_language: string
  page_referrer: string
  page_title: string
  page_url: string | null
  platform: string
  resolution: string
  dstags: string
  form_fields: Record<string, unknown>
}

export const getFormatData = (event: string, formFields = {}, tags = ''): IFormatData => {
  const pageLanguage = window.navigator.language
  const pageReferrer = window.document.referrer
  const pageTitle = window.document.title
  const platform = window.navigator.userAgent
  const resolution = window.screen.availWidth + 'x' + window.screen.availHeight

  return {
    event: event,
    page_language: pageLanguage,
    page_referrer: pageReferrer,
    page_title: pageTitle,
    page_url: decodeURI(getPageURL()),
    platform: platform,
    resolution: resolution,
    dstags: tags,
    form_fields: formFields,
  }
}

export const serializeJSON = (form: HTMLFormElement): Record<string, string> => {
  const obj: Record<string, string> = {}
  const elements = form.querySelectorAll<HTMLInputElement>('input, select, textarea')
  elements.forEach((element) => {
    const name = element.name
    const value = element.value
    if (name) obj[name] = value
  })

  return obj
}

export const getScrollPercent = (): number => {
  const h = document.documentElement
  const b = document.body
  const st = 'scrollTop'
  const sh = 'scrollHeight'
  return h && b ? ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100 : 0
}

export const isEmpty = (obj: any): boolean => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false
    }
  }

  return JSON.stringify(obj) === JSON.stringify({})
}

export const objectToQueryString = (obj: Record<string, string | number | boolean>): string => {
  return (
    '?' +
    Object.keys(obj)
      .reduce((a: any, k: any) => {
        a.push(k + '=' + encodeURIComponent(obj[k]))
        return a
      }, [])
      .join('&')
  )
}

export const getLocalStorage = <T>(key: string, defaultValue?: T, isObject = true): T | string => {
  const val = window.localStorage.getItem(key)
  if (val === null) {
    return defaultValue || ''
  }

  if (isObject) {
    try {
      return JSON.parse(val) as T
    } catch {
      return (defaultValue || {}) as T
    }
  }

  return val
}

export const getContactId = (): string | null => {
  return window.localStorage.getItem('contact_id')
}

export const isPushApiSupported = (): boolean => {
  return 'PushManager' in window
}

export enum DBType {
  LOGIN = 'LOGIN',
  PUBLIC = 'PUBLIC',
}

export const getCurrentDBType = (): DBType => {
  const dbType = window.localStorage.getItem('db_type') || DBType.PUBLIC
  return dbType as DBType
}

export const parseBool = (val: string): boolean => {
  return val === 'true'
}

export const sleep = async (mSec: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(() => resolve(), mSec))
}
