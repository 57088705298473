import { ITrackerResponse } from './Service/types'

export class PamEvent {
  constructor(public name: string, public formFields: Record<string, unknown>, public tags?: string[]) {}

  public get isTrackingEvent(): boolean {
    return !['login', 'logout'].includes(this.name)
  }

  public get tagsString(): string {
    return (this.tags ?? []).join()
  }
}

export class PromisePamEvent extends PamEvent {
  constructor(
    public name: string,
    public formFields: Record<string, unknown>,
    public resolve: (res: ITrackerResponse) => void,
    public reject: (reason?: any) => void,
    public tags?: string[]
  ) {
    super(name, formFields, tags)
  }
}
